import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      // console.log(locale, locales(key));
      messages[locale] = locales(key);
    }
  });
  return messages;
}

let i18n = new VueI18n({
  defaultLocale: process.env.VUE_APP_I18N_DEFAULT_LOCALE || "ru",
  locale: process.env.VUE_APP_I18N_LOCALE || "ru",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru",
  messages: loadLocaleMessages(),
});

i18n.setLocale = (locale) => {
  i18n.locale = locale;
};

i18n.getTranslation = (data) => {
  // console.log(data)
  const locale = i18n.locale || null;
  try {
    if (locale) {
      return data[locale];
    }
  } catch (e) {
    // console.log(e)
  }

  return "";
};

export default i18n;
// new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// });
