const state = () => ({
  list: null,
  current: null,
  roles: [],
});

const mutations = {
  setUsersList(state, data) {
    state.list = data;
  },
  setUserCurrent(state, data) {
    state.current = data;
  },
  SetRoles: (state, roles) => {
    state.roles = roles;
  },
};

const actions = {
  async FetchRoles({ state, commit }) {
    // console.log(state.roles)
    // console.log(this);
    if (!state.roles || state.roles.length === 0) {
      const rolesResponse = await this.$strapi.request(
        "GET",
        "/users-permissions/roles"
      );
      // console.log(rolesResponse);
      commit("SetRoles", rolesResponse.roles);
    }

    return state.roles;
  },
  async fetchUsersList({ commit }, listQuery) {
    // console.log(listQuery);
    // if (state.list) { return state.list }
    let response;
    try {
      response = await this.$strapi.getEntries("users", listQuery); // { id: data.id, _sort: 'createdAt:desc' })
    } catch (err) {
      // console.log('fetchUsersList Error: ' + err)
      // commit("setUsersList", null);
      return [];
    }
    // console.log('fetchUsersList: ' + JSON.stringify(response))
    // commit("setUsersList", response);
    return response;
  },
  async fetchUser({ commit }, id) {
    const response = await this.$strapi.getEntry("users", id);
    // console.log(response)
    await commit("setUserCurrent", response);
    return response;
  },
  async countUser({ commit }) {
    const response = await this.$strapi.getEntries("users", {});
    // console.log(response)
    return response.length;
  },
  async createUser({ commit }, data) {
    const response = await this.$strapi.createEntry("users", data);
    // console.log(response)
    await commit("setUserCurrent", response);
    return response;
  },
  async updateUser({ commit }, data) {
    // console.log(data.id, data.data);
    const response = await this.$strapi.updateEntry(
      "users",
      data.id,
      data.data
    );
    // console.log(response);
    // await commit('setUserCurrent', response);
    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
