export default async function ({ next, store }) {
  // If the user is not authenticated
  if (!store.state.authCustom.auth) {
    if (await store.dispatch("authCustom/initJWT")) {
      if (store.state.authCustom.auth) {
        return next();
      }
    }
    return next({
      path: "/auth/login",
    });
  }
  return next();
}
