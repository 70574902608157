const state = () => ({
    list: null,
    current: null,
});

const mutations = {
    setTestResultsList(state, data) {
        state.list = data;
    },
    setTestResultCurrent(state, data) {
        state.current = data;
    },
};

const actions = {
    async fetchTestResultsListManage({ commit }, listQuery) {
        let response;
        try {
            response = await this.$strapi.getEntries("testresults", listQuery);
        } catch (err) {
            return [];
        }
        return response.data;
    },
    async fetchTestResultsList({ commit }, listQuery) {
        // if (state.list) { return state.list }
        let response;
        try {
            response = await this.$strapi.getEntries("testresults", listQuery); // { id: data.id, _sort: 'createdAt:desc' })
        } catch (err) {
            // console.log('fetchTestResultsList Error: ' + err)
            commit("setTestResultsList", null);
            return [];
        }
        // console.log('fetchTestResultsList: ' + JSON.stringify(response))
        commit("setTestResultsList", response.data);
        return response.data;
    },
    async fetchTestresult({ commit }, id) {
        const response = await this.$strapi.getEntry("testresults", id);
        // console.log(response)
        await commit("setTestResultCurrent", response);
        return response;
    },
    async createTestresult({ commit }, data) {
        const response = await this.$strapi.createEntry("testresults", data);
        // console.log(response)
        await commit("setTestResultCurrent", response);
        return response;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
