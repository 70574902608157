import Vue from "vue";
import Router from "vue-router";
import store from "../store";

import LayoutDefault from "@/layouts/default/index.vue";
import LayoutError from "@/layouts/error/index.vue";

import authenticated from "./../middleware/authenticated";
import notAuthenticated from "./../middleware/notAuthenticated";
import uiDefaultState from "./../middleware/uiDefaultState";
// import backgroundClean from './../middleware/backgroundClean';
import replainWidget from "./../middleware/replainWidget";
import middlewarePipeline from "./middlewarePipeline";

Vue.use(Router);

const routes = [
    {
        path: "/",
        component: LayoutDefault,
        // redirect: "/home-page",
        children: [
            {
                path: "/",
                component: () => import("../views/home-page/index"),
                name: "",
                meta: { title: "", middleware: [uiDefaultState, replainWidget] },
            },
        ],
    },
    {
        path: "/profile",
        component: LayoutDefault,
        redirect: "/profile",
        children: [
            {
                path: "/profile",
                component: () => import("../views/profile/index"),
                name: "",
                meta: { middleware: [uiDefaultState, authenticated, replainWidget] },
            },
        ],
    },
    {
        path: "/manage",
        component: LayoutDefault,
        redirect: "/manage/index",
        children: [
            {
                path: "/manage/index",
                component: () => import("../views/manage/index"),
                name: "",
                meta: { middleware: [uiDefaultState, authenticated] },
            },
        ],
    },
    {
        path: "/join",
        component: LayoutDefault,
        children: [
            {
                path: "/join/:udid",
                component: () => import("../views/join/index"),
                name: "",
                meta: { middleware: [uiDefaultState] },
            },
        ],
    },
    {
        path: "/auth",
        component: LayoutDefault,
        redirect: "/auth/login",
        children: [
            // {
            //   path: "/auth/:provider/:code",
            //   component: () => import("../views/auth/index"),
            //   name: "",
            //   meta: { middleware: [uiDefaultState, notAuthenticated] }
            // },
            {
                path: "/auth/registration",
                component: () => import("../views/auth/registration"),
                name: "",
                meta: { middleware: [uiDefaultState, notAuthenticated, replainWidget] },
            },
            {
                path: "/auth/login",
                component: () => import("../views/auth/login"),
                name: "",
                meta: { middleware: [uiDefaultState, notAuthenticated, replainWidget] },
            },
            {
                path: "/auth/forgot-password",
                component: () => import("../views/auth/forgot-password"),
                name: "",
                meta: { middleware: [uiDefaultState, notAuthenticated, replainWidget] },
            },
            {
                path: "/auth/reset-password/:code",
                component: () => import("../views/auth/reset-password"),
                name: "",
                meta: { middleware: [uiDefaultState, notAuthenticated, replainWidget] },
            },
        ],
    },
    {
        path: "/tests",
        component: LayoutDefault,
        redirect: "/404",
        children: [
            {
                path: "/tests/szondi",
                component: () => import("../views/tests/szondi"),
                name: "",
                meta: { middleware: [uiDefaultState] },
            },
            {
                path: "/tests/quadro",
                component: () => import("../views/tests/quadro"),
                name: "",
                meta: { middleware: [uiDefaultState] },
            },
        ],
    },
    {
        path: "/results",
        component: LayoutDefault,
        redirect: "/results/all",
        children: [
            {
                path: "/results/all",
                component: () => import("../views/results/all"),
                name: "",
                meta: { middleware: [uiDefaultState, authenticated] },
            },
            {
                path: "/results/:udid",
                component: () => import("../views/results/index"),
                name: "",
                meta: { middleware: [uiDefaultState] },
            },
        ],
    },
    {
        path: "/ru",
        component: LayoutDefault,
        redirect: '/ru/privacy-policy',
        children: [
            {
                path: "/ru/privacy-policy",
                component: () => import("../views/ru/privacy-policy"),
                name: "",
                meta: { middleware: [uiDefaultState] },
            },
            {
                path: "/ru/data-deletion",
                component: () => import("../views/ru/data-deletion"),
                name: "",
                meta: { middleware: [uiDefaultState] },
            },
        ],
    },
    {
        path: "/en",
        component: LayoutDefault,
        redirect: '/en/privacy-policy',
        children: [
            {
                path: "/en/privacy-policy",
                component: () => import("../views/en/privacy-policy"),
                name: "",
                meta: { middleware: [uiDefaultState] },
            },
            {
                path: "/en/data-deletion",
                component: () => import("../views/en/data-deletion"),
                name: "",
                meta: { middleware: [uiDefaultState] },
            },
        ],
    },
    {
        path: "/404",
        component: LayoutError,
        hidden: true,
        children: [
            {
                path: "",
                component: () => import("@/views/error-page/404"),
                meta: { middleware: [uiDefaultState, replainWidget] },
            },
        ],
    },
    {
        path: "/401",
        component: LayoutError,
        hidden: true,
        children: [
            {
                path: "",
                component: () => import("@/views/error-page/401"),
                meta: { middleware: [uiDefaultState, replainWidget] },
            },
        ],
    },
    // 404 page must be placed at the end !!!
    { path: "*", redirect: "/404", hidden: true },
];

const createRouter = () =>
    new Router({
        mode: "history", // require service support
        scrollBehavior: () => ({ y: 0 }),
        routes,
    });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// export function resetRouter() {
//   const newRouter = createRouter();
//   router.matcher = newRouter.matcher; // reset router
// }

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next();
    }
    const middleware = to.meta.middleware;

    const context = {
        to,
        from,
        next,
        store,
    };

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

export default router;
