<template>
  <div id="components-back-top-custom">
    <a-back-top>
      <div class="ant-back-top-inner">
        <a-icon type="to-top" />
      </div>
    </a-back-top>
    <a-locale-provider :locale="$store.state.ui.locale">
      <div
        :key="(!!$store.state.ui.locale).toString()"
        class="locale-components"
      >
        <!-- HACK: just refresh in production environment-->

        <a-layout>
          <div :style="{ 'min-height': '100vh' }">
            <Header
              v-if="$store.state.ui.visibleHeader"
              :style="{ 'min-height': '64px' }"
            />
            <!-- <a-layout> -->
            <!-- <a-layout-sider v-if="false"></a-layout-sider> -->
            <a-layout-content>
              <app-main />
            </a-layout-content>
            <!-- <a-layout-sider v-if="false"></a-layout-sider> -->
            <!-- </a-layout> -->
          </div>
          <Footer
            v-if="$store.state.ui.visibleFooter"
            :style="{ 'background-color': 'rgb(50,70,90)' }"
          />
        </a-layout>
      </div>
    </a-locale-provider>
  </div>
</template>
<script>
import { AppMain, Header, Footer } from "./components";

export default {
  components: { Header, Footer, AppMain },
  // head:{
  //    title: function () {
  //     return {
  //       inner: this.title
  //     }
  //   },
  //   meta: [
  //     { name: 'description', content: 'My description', id: 'desc' }
  //   ]
  // }
};
</script>

<style scoped>
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}
</style>
<style scoped>
#components-back-top-custom {
  min-height: 100vh;
}

#components-back-top-custom .ant-back-top {
  bottom: 100px;
  right: 35px;
}
#components-back-top-custom .ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 30px;
  background-color: #0c93a4;
  color: white;
  text-align: center;
  font-size: 20px;
}
</style>
