<template>
  <a-affix>
    <a-layout-header>
      <!--  -->
      <router-link
        to="/"
        exact
        class="home_logo"
        @click.right.native.prevent="showHomepage"
      >
        <a-avatar src="/logo.png" style="width: 50px; height: 50px" />
      </router-link>
      <a-button
        v-if="this.$store.state.ui.actionButtonVisible"
        class="actionBtn"
        type="primary"
        size="small"
        :disabled="this.$store.state.ui.actionButtonDisabled"
        @click="callActionButtonFunction"
        >{{ $t(this.$store.state.ui.actionButtonLabelId) }}</a-button
      >
      <a-menu
        v-model="current"
        mode="horizontal"
        class="header_menu"
        @click="handleClick"
        @titleClick="handleTitleClick"
      >
        <!--  -->
        <!-- <a-menu-item key="blog" class="right-aligned" disabled>
          <router-link to="/blog')" exact tag="a">
            <a-icon type="read" />{{ $t('topmenu.blog') }}
          </router-link>
        </a-menu-item>-->
        <!--  -->
        <a-sub-menu class="right-aligned">
          <span slot="title" class="submenu-title-wrapper">
            <a-icon type="book" />
            {{ $t("topmenu.tests") }}
          </span>
          <a-menu-item-group :title="$t('topmenu.sondi-submenu')">
            <a-menu-item key="sondi:1">
              <router-link to="/tests/szondi">
                <a-icon type="profile" />
                {{ $t("temp.test-szondi-classical") }}
              </router-link>
            </a-menu-item>
          </a-menu-item-group>
          <a-menu-item key="quadro">
            <router-link to="/tests/quadro">
              <a-icon type="picture" />
              {{ $t("temp.quadro-test") }}
            </router-link>
          </a-menu-item>
        </a-sub-menu>
        <!--  -->
        <a-menu-item
          v-if="!$store.state.authCustom.auth"
          key="registration"
          class="right-aligned"
        >
          <router-link to="/auth/registration">
            <a-icon type="user-add" />
            {{ $t("topmenu.registration") }}
          </router-link>
        </a-menu-item>
        <!--  -->
        <a-menu-item
          v-if="!$store.state.authCustom.auth"
          key="login"
          class="right-aligned"
        >
          <router-link to="/auth/login">
            <a-icon type="user" />
            {{ $t("topmenu.login") }}
          </router-link>
        </a-menu-item>
        <!--  -->
        <a-sub-menu v-if="$store.state.authCustom.auth" class="right-aligned">
          <span slot="title" class="submenu-title-wrapper">
            <a-icon type="user" />
            {{ $t("topmenu.myprofile") }}
          </span>
          <!-- <a-menu-item v-if="groupsAvailable" key="groups" disabled>
            <router-link to="groups">
              <a-icon type="folder" />{{ $t("topmenu.groups") }}
            </router-link>
          </a-menu-item>-->
          <a-menu-item key="results">
            <router-link to="/results">
              <a-icon type="database" />
              {{ $t("topmenu.testresults") }}
            </router-link>
          </a-menu-item>
          <a-menu-item key="profile">
            <router-link to="/profile">
              <a-icon type="profile" />
              {{ $t("topmenu.editprofile") }}
            </router-link>
          </a-menu-item>
          <a-menu-item v-if="canManage" key="dashboard">
            <router-link to="/manage">
              <a-icon type="compass" />
              {{ $t("topmenu.manage") }}
            </router-link>
          </a-menu-item>
          <a-menu-item key="logout">
            <a-icon type="logout" />
            {{ $t("topmenu.logout") }}
          </a-menu-item>
        </a-sub-menu>
        <!--  -->
        <a-sub-menu class="right-aligned">
          <span slot="title" class="submenu-title-wrapper">
            <a-icon type="global" />
            {{
              this.$i18n.locale
                ? this.$i18n.locale.toUpperCase()
                : this.$i18n.defaultLocale.toUpperCase()
            }}
          </span>
          <a-menu-item
            v-for="item in availableLocales"
            :key="item"
            style="text-align: center"
            @click="changeLocale(item)"
          >
            <div
              style="
                display: flex;
                justify-content: center;
                vertical-align: middle;
              "
            >
              <!-- <img :src="'/icons/' + item.code + '_lang.png'" style="width:30px;height:20px;margin-right:10px;margin-top:10px;" /> -->
              {{ item ? item.toUpperCase() : "" }}
            </div>
          </a-menu-item>
        </a-sub-menu>
        <!--  -->
      </a-menu>
    </a-layout-header>
    <!-- <router-link
      v-for="locale in availableLocales"
      :key="locale.code"
      :to="locale.code"
    >
      {{ locale.name }}
    </router-link>-->
  </a-affix>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";

let s = null;
let x = null;

export default {
  data() {
    return {
      current: null,
      testListsProp: this.testsList,
    };
  },
  computed: {
    ...mapGetters([
      "role",
      // ...
    ]),
    canManage() {
      // console.log(this.getRole);
      if (!this.role) {
        return false;
      }
      return (
        this.role.name === "администратор" || this.role.name === "интерпретатор"
      );
    },
    getRole() {
      return this.role;
    },
    groupsAvailable() {
      return false;
    },
    testsList() {
      return this.getTestsList;
    },
    availableLocales() {
      // console.log(this.$i18n.availableLocales);
      return this.$i18n.availableLocales.filter((i) => i !== this.$i18n.locale);
    },
  },
  beforeCreate() {},

  async mounted() {
    await this.$store.dispatch("tests/fetchTestsList");
    // this.showManage = this.canManage();
    // console.log(this.canManage());

    // console.log(this.$i18n);
    // if (!this.$isServer) {
    // if (this.$route.fullPath !== '/test-szondi') {
    if (!this.s && !this.x) {
      window.replainSettings = { id: "2b03e3b5-0a09-4aab-add7-8356d311c993" };

      this.s = document.createElement("script");
      this.s.type = "text/javascript";
      this.s.async = true;
      this.s.src = "https://widget.replain.cc/dist/client.js";
      this.x = document.getElementsByTagName("script")[0];
      this.x.parentNode.insertBefore(this.s, this.x);

      this.waitFor(5000).then(() => {
        // let myiFrame = document.getElementById("__replain_widget_iframe");

        // let doc = myiFrame.contentDocument;
        // doc.body.innerHTML =
        //   doc.body.innerHTML +
        //   "<style>#app > div.btn > span.notification.accent-color-bg { display: none !important; } #__replain_widget_iframe {#app > div.btn > span.notification.accent-color-bg { display: none !important; } }</style>";
      });

      // const show = false
      // const xe = document.getElementById('__replain_widget_iframe')
      // if (xe) {
      //   xe.style.display = show ? 'unset' : 'none'
      // }
      // console.log(this.s)
      // console.log(this.x)
      // }
    }
    // }
  },
  methods: {
    callActionButtonFunction() {
      if (!this.$isServer) {
        if (this.$store.state.ui.actionFunc) {
          this.$store.state.ui.actionFunc();
        }
      }
    },
    changeLocale(key) {
      // console.log(key);
      this.$store.commit("ui/setLocale", key);
    },
    handleClick(item) {
      // { item, key, keyPath }) {
      const key = item.key ? item.key : item;
      switch (key) {
        // case 'sondi:1':
        //   this.$router.push('/test-szondi')
        //   break

        case "ru":
        case "en":
        case "de":
          // console.log(key)
          this.$store.commit("ui/setLocale", key);
          break;
        case "logout":
          this.logout();
          // this.$router.push('')
          this.$router.push("/");
          break;
        default:
        // eslint-disable-next-line no-case-declarations
        // const url =
        //   item.type === 'Szondi'
        //     ? '/test-szondi?id=' + item.id
        //     : item.type === 'Quadro'
        //       ? '/test-quadro?id=' + item.id
        //       : key

        // console.log(url)
      }
    },
    handleTitleClick() {
      // { item, key, domEvent }) {
      // console.log('handleTitleClick: ' + key)
    },
    showHomepage() {
      this.$router.push("/");
    },

    ...mapMutations({
      logout: "authCustom/logout",
      // setLang: 'i18n/logout'
    }),
    waitFor(ms) {
      return new Promise(function (resolve) {
        setTimeout(resolve, ms || 0);
      });
    },
  },
};
</script>

<style scoped>
/* .right-aligned { */
/* float: right; */
/* } */
.ant-layout-header {
  background-color: white;
  padding: 0;
  border-bottom: 1px solid #e8e8e8;
}
.home_logo {
  margin: 0px;
  margin-left: 10px;
  /* margin-top: 13px; */
  /* position: fixed; */
  /* float: left; */
  /* display: inline-flex; */
}
.header_menu {
  padding-top: 8px;
  background-color: white;
  /* display: inline-flex; */
  float: right;
  position: relative;
  border-bottom: 0;
  max-width: calc(100% - 300px);
}

@media only screen and (max-width: 700px) {
  .dividerTitle {
    display: none;
  }
}

.actionBtn {
  /* cursor: pointer;
  width: 150px;
  height: 60px;
  font-size: 200%;
  display: inline-flex;
  text-align: right;
  min-width: 100px;
  margin-top: 2px; */
  margin-left: 20px;
}
</style>
