/* eslint-disable camelcase */
import ru_RU from "ant-design-vue/lib/locale-provider/ru_RU";
import de_DE from "ant-design-vue/lib/locale-provider/de_DE";
import en_US from "ant-design-vue/lib/locale-provider/en_US";
// import ruLocale from 'moment/locale/ru'
// import deLocale from 'moment/locale/de'

const state = () => ({
  visibleHeader: true,
  visibleFooter: true,
  actionButtonVisible: false,
  actionButtonLabelId: "",
  actionButtonDisabled: false,
  // : {
  //   type: Function, default: () => { } },
  locale: ru_RU,
  ru_RU,
  de_DE,
  en_US,

  sondiPopoverIndex: -1,
  circlePopoverIndex: -1,
  uiBackground: "unset",
});

const mutations = {
  setUiBackground(state, uiBackground) {
    // console.log(uiBackground);
    state.uiBackground = uiBackground;
  },
  setCirclePopoverIndex(state, index) {
    state.circlePopoverIndex = index;
  },
  setSondiPopoverIndex(state, index) {
    state.sondiPopoverIndex = index;
  },
  setLocale(state, key) {
    this.$i18n.setLocale(key);
    this.$cookies.set("locale", key);
    switch (key) {
      case "en":
        // state.locale = null
        state.locale = state.en_US;
        // this.$moment.locale(null)
        break;
      case "ru":
        state.locale = state.ru_RU;
        // this.$moment.locale(ruLocale)
        break;
      case "de":
        state.locale = state.de_DE;
        // this.$moment.locale(deLocale)
        break;
    }
    this.$moment.locale(key);
  },
  setHeaderFooterVis(state, data) {
    state.visibleHeader =
      data.visibleHeader === undefined
        ? state.visibleHeader
        : data.visibleHeader;
    state.visibleFooter =
      data.visibleFooter === undefined
        ? state.visibleFooter
        : data.visibleFooter;
  },
  setActionButton(state, { data, callback }) {
    state.actionButtonLabelId =
      data.textId === undefined ? state.actionButtonLabelId : data.textId;
    state.actionFunc = callback === undefined ? state.actionFunc : callback;
    state.actionButtonVisible =
      data.visible === undefined ? state.actionButtonVisible : data.visible;
    state.actionButtonDisabled =
      data.disabled === undefined ? state.actionButtonDisabled : data.disabled;
  },
};

const actions = {
  initLocale({ commit }, browserLocale) {
    // console.log(this);
    const locale = this.$cookies.get("locale");
    if (locale) {
      commit("setLocale", locale);
    } else {
      if (browserLocale.includes("en")) {
        commit("setLocale", "en");
      }
      if (browserLocale.includes("de")) {
        commit("setLocale", "de");
      }
      if (browserLocale.includes("ru")) {
        commit("setLocale", "ru");
      }
    }
  },
};
// const getters = {
//   getHeaderFooterVis(state) {
//     return {
//       visibleHeader: state.visibleHeader,
//       visibleFooter: state.visibleFooter
//     }
//   }
// }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
