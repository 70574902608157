const state = () => ({
    list: null,
});

const mutations = {
    setTestsList(state, data) {
        state.list = data;
    },
};

const actions = {
    async fetchTestsList({ state, commit }) {
        if (state.list) {
            return state.list;
        }
        let response;
        try {
            // response = await this.$strapi.query('tests').find() // getEntries('tests') //
            // response = await this.$axios.$get(this.$strapi.apiUrl + '/tests')
            response = await this.$strapi.request("GET", "/tests?populate=*");
            // this.$strapi.getEntries('tests')
        } catch (err) {
            // console.log('fetchTestsList: ' + err)
            return [];
        }
        // console.log('fetchTestsList: ')
        // console.log(response)
        commit("setTestsList", response.data);
        return response.data;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
