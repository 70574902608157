function replainWidgetVisibility(show) {
  // if (!process.server) {
  const x = document.getElementById("__replain_widget");
  if (x) {
    x.style.visibility = show ? "visible" : "hidden";
  }
  // console.log(x);
  // }
}

export default function ({ next, store }) {
  replainWidgetVisibility(true);

  return next();
}
