import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";

// import Es6Promise from 'es6-promise';

// Es6Promise.polyfill();

// import "babel-polyfill";

import "@/styles/index.scss"; // global css

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
Vue.config.productionTip = false;

Vue.use(Antd);

import VueKonva from "vue-konva";

Vue.use(VueKonva);

const moment = require("moment-timezone");

import strapi from "@/plugins/strapi";
Vue.use(strapi);

import i18n from "./i18n";
i18n.baseUrl = strapi.baseUrl;
i18n.apiUrl = strapi.apiUrl;
i18n.storageUrl = strapi.storageUrl;

import device from "vue-device-detector";
Vue.use(device);

Vue.use(require("vue-cookies"));
Vue.$cookies.config("30d");

Vue.config.productionTip = false;

store.$i18n = i18n;
store.$moment = moment;
store.$strapi = Vue.prototype.$strapi;
store.$cookies = Vue.$cookies;

import GAuth from "vue-google-oauth2";
const gauthOption = {
  clientId:
    "572143138171-oitb754de2r9u398ir2510c34s45figm.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account",
  fetch_basic_profile: false,
};
Vue.use(GAuth, gauthOption);

import VFacebookLogin from "vue-facebook-login-component";
Vue.use(VFacebookLogin);

import VueMeta from "vue-meta";

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

import VueAnalytics from "vue-analytics";

Vue.use(VueAnalytics, {
  id: "UA-117685768-4",
  router: router,
  checkDuplicatedScript: true,
});

import VueYandexMetrika from "./vue-yandex-metrika";

Vue.use(VueYandexMetrika, {
  id: 54962779,
  router: router,
  env: process.env.NODE_ENV,
  webvisor: false,
  accurateTrackBounce: true,
  clickmap: true,
  trackLinks: true,

  // other options
});

import VueFacebookPixel from "vue-analytics-facebook-pixel";

Vue.use(VueFacebookPixel);

Vue.analytics.fbq.init("1279727715544876", {
  em: "admin@psyhotest.com",
  router: router,
});

// import VueInstaller from "vue-pwa-installer";
// Vue.use(VueInstaller, {
//   /* options */
// });

new Vue({
  i18n,
  moment,
  device,
  strapi,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
