import sha256 from 'crypto-js/sha256';
import hmacSHA256 from 'crypto-js/hmac-sha256';

import axios from 'axios';

const state = () => ({
    auth: undefined,
    user: undefined,
    interpretations: undefined,
});

const mutations = {
    setAuth(state, auth) {
        state.auth = auth;
        this.$strapi.setToken(auth);
    },
    setUser(state, user) {
        state.user = user;
    },
    logout(state) {
        // console.log("logout");
        state.user = undefined;
        state.auth = undefined;
        state.interpretations = undefined;
        this.$strapi.clearToken();
        this.$cookies.remove("jwt");
    },
    updateUser(state, data) {
        if (state.user) {
            state.user.firstname = data.firstname || state.user.firstname;
            state.user.secondname = data.secondname || state.user.secondname;
            state.user.dateofbirth = data.dateofbirth || state.user.dateofbirth;
            state.user.gender = data.gender || state.user.gender;
        }
    },
    setInterpretations(state, data) {
        state.interpretations = data;
    },
};

// const getters = {
//   // getAuth: state => {
//   //   return (
//   //     state.auth != null &&
//   //     state.auth !== undefined &&
//   //     state.auth.trim().length > 0
//   //   )
//   // },
//   // id: state => {
//   //   return state.user && state.user.id
//   // },
//   gender: state => {
//     return state.user && state.user.gender
//   },
//   profile: state => {
//     return (
//       state.user && {
//         email: state.user.email,
//         firstname: state.user.firstname,
//         secondname: state.user.secondname,
//         dateofbirth: state.user.dateofbirth
//       }
//     )
//   }
// }

const actions = {
    removeGroupRequest({ commit, dispatch }) {
        this.$cookies.remove("group");
    },
    getGroupRequest({ commit, dispatch }) {
        const group = this.$cookies.get("group");
        return group;
    },
    setGroupRequest({ commit, dispatch }, udid) {
        this.$cookies.set("group", udid);
    },
    async getConfirmEmail() {
        return this.$cookies.get("confirm-email");
    },
    async initJWT({ commit, dispatch }) {
        const jwt = this.$cookies.get("jwt");
        if (jwt) {
            commit("setAuth", jwt);
            await dispatch("authAction");
        }
        return jwt;
    },
    async fetchInterpretations({ commit, state }) {
        if (state.interpretations) {
            return state.interpretations;
        } else {
            try {
                const response = await this.$strapi.getEntries("interpretations");
                commit("setInterpretations", response);
                return response;
            } catch (err) {
                // console.log(err);
                // commit("logout");
            }
        }
        return [];
    },
    async authAction({ state, commit, dispatch }) {
        if (state.auth) {
            try {
                const response = await this.$strapi.request("GET", "/users/me");
                // console.log('/users/me')
                // console.log(response)
                commit("setUser", response);

                const groupUdid = await dispatch("getGroupRequest");
                // console.log(groupUdid);
                if (groupUdid) {
                    dispatch("removeGroupRequest");
                    const data = {
                        id: -1,
                        data: { udid: groupUdid },
                    };
                    dispatch("groups/updateGroup", data, { root: true });
                }
            } catch (err) {
                // console.log(err);
                commit("logout");
            }
        } else {
            commit("logout");
        }
        // } else { this.$strapi.clearToken() }
    },
    async telegramLogin({ commit, dispatch }, authResponse) {
        //console.log(authResponse);
        if (authResponse) {
            // authResponse.access_token = authResponse.accessToken;
            const checkHash = authResponse.hash;
            const dataCheckString = Object.keys(authResponse)
                .map(function (k) {
                    return (
                        k + "=" + authResponse[k]
                    );
                })
                .join("\n");
            const secretKey = sha256(process.env.BOT_TOKEN);
            const hash = hmacSHA256(dataCheckString, secretKey);

            consolee.log(checkHash);
            //console.log(dataCheckString);
            //console.log(secretKey);
            //console.log(hash);

            //console.log(hash == checkHash);

            if (hash == checkHash) {
                commit("setAuth", hash);
                await dispatch("authAction", hash);

                await dispatch('updateProfileAction', {
                    firstname: authResponse.first_name,
                    secondname: authResponse.last_name,
                    gender: true
                })

                return {
                    firstname: authResponse.first_name,
                    secondname: authResponse.last_name,
                    gender: true
                };
            }

            return false;
        }
    },
    async facebookLogin({ commit, dispatch }, authResponse) {
        if (authResponse) {
            authResponse.access_token = authResponse.accessToken;
            const url = Object.keys(authResponse)
                .map(function (k) {
                    return (
                        encodeURIComponent(k) + "=" + encodeURIComponent(authResponse[k])
                    );
                })
                .join("&");
            const response = await this.$strapi.request(
                "GET",
                "/auth/facebook/callback?" + url
            );
            commit("setAuth", response.jwt);
            await dispatch("authAction", response.jwt);
            return response;
        }
    },
    async googleLogin({ commit, dispatch }, GoogleUser) {
        //console.log('AuthResponse', GoogleUser.getAuthResponse());
        if (GoogleUser) {
            let token = GoogleUser.getAuthResponse().access_token;

            const response = await this.$strapi.request(
                "GET",
                "/auth/google/callback?access_token=" + token
            );
            commit("setAuth", response.jwt);
            await dispatch("authAction", response.jwt);
            return response;
        }
    },
    async loginAction({ dispatch, commit }, params) {
        let response;
        try {
            this.$strapi.clearToken();
            response = await this.$strapi.login(params.email, params.password);
            commit("setAuth", response.jwt);
            //
            // this.$strapi.setToken(response.jwt)
            //

            this.$cookies.remove("confirm-email");
            await dispatch("authAction", response.jwt);
        } catch (err) {
            // console.log(err);
            // commit("logout");
            return null;
        }
        // console.log('response: ' + JSON.stringify(response.user))
        return response.user;
    },
    async updateProfileAction({ commit }, params) {
        let response;
        try {
            //console.log(params)
            // this.$strapi.up

            let data = {
                // email: params.email,
                // password: params.password,
                // username: params.email,
                firstname: params.firstname,
                secondname: params.secondname,
                gender: params.gender == 1,
            }

            if (params.dateofbirth) {
                data.dateofbirth = params.dateofbirth;
            }

            response = await this.$strapi.request("PUT", "/users/" + params.id, {
                data: data
            });
            commit("updateUser", {
                firstname: params.firstname,
                secondname: params.secondname,
                gender: params.gender,
                dateofbirth: params.dateofbirth,
            });
            // dispatch('authAction', response.jwt)
        } catch (err) {
            // console.log(err);
            // commit("logout");
            return null;
        }
        // console.log('response: ' + JSON.stringify(response))
        return response;
    },
    async registerAction({ dispatch, commit }, params) {
        //console.log('registerAction');
        let response;
        try {
            // console.log(params)
            this.$strapi.clearToken();
            response = await axios.post(this.$strapi.apiUrl + '/auth/local/register',
                {
                    username: params.email,
                    email: params.email,
                    password: params.password,
                    firstname: params.firstname,
                    secondname: params.secondname,
                    gender: params.gender == 1,
                    dateofbirth: params.dateofbirth,
                })

            // request("POST", "/auth/local/register", {
            //   data: {
            //     email: params.email,
            //     password: params.password,
            //     username: params.email,

            //   },
            // });

            // this.$cookies.set("confirm-email", true);
            // commit("setAuth", response.jwt);
            //
            // this.$strapi.setToken(response.jwt)
            //
            // await dispatch("authAction", response.jwt);
        } catch (err) {
            // console.log(err);
            // commit("logout");
            return null;
        }
        // console.log('response: ' + JSON.stringify(response.user))
        return response.user;
    },
    async forgotPasswordAction({ commit }, params) {
        let response;
        try {
            response = await this.$strapi.forgotPassword(
                params.email
            );
        } catch (err) {
            // console.log(err);
            // commit("logout");
            return null;
        }
        // console.log('response: ', response);
        return { success: true };
    },
    async resetPasswordAction({ commit }, params) {
        let response;
        try {
            response = await this.$strapi.resetPassword(
                params.code,
                params.password,
                params.password
            );
        } catch (err) {
            // console.log(err);
            // commit("logout");
            return null;
        }
        // console.log('response: ' + JSON.stringify(response))
        return response;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
