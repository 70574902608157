const state = () => ({
    list: null,
    current: null,
});

const mutations = {
    setGroupList(state, data) {
        state.list = data;
    },
    setGroupCurrent(state, data) {
        state.current = data;
    },
};

const actions = {
    async fetchGroupsList({ commit }, listQuery) {
        // if (state.list) { return state.list }
        let response;
        try {
            response = await this.$strapi.getEntries("groups", listQuery); // { id: data.id, _sort: 'createdAt:desc' })
        } catch (err) {
            // console.log('fetchGroupList Error: ' + err)
            commit("setGroupList", null);
            return [];
        }
        // console.log('fetchGroupList: ' + JSON.stringify(response))
        commit("setGroupList", response.data);
        return response.data;
    },
    async fetchGroup({ commit }, id) {
        const response = await this.$strapi.getEntry("groups", id);
        // console.log(response)
        await commit("setGroupCurrent", response);
        return response;
    },
    async createGroup({ commit }, data) {
        const response = await this.$strapi.createEntry("groups", data);
        //console.log(response);
        // console.log(response)
        // await commit("setGroupCurrent", response);
        return response;
    },
    async updateGroup({ commit }, data) {
        // console.log(data.id, data.data);
        const response = await this.$strapi.updateEntry(
            "groups",
            data.id,
            {
                data: data.data
            }
        );
        // console.log(response);
        // await commit('setGroupCurrent', response);
        return response;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
