var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"app-main",style:({
    'background-image': _vm.$store.state.ui.uiBackground,
    'background-repeat': 'no-repeat',
    'background-attachment': 'inherit',
    'background-size': 'cover',
    'background-position-x': 'center',
  })},[_c('transition',{attrs:{"name":"fade-transform","mode":"out-in"}},[_c('router-view',{key:_vm.key})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }