const getters = {
  uiBackground: (state) => state.ui.uiBackground,
  getTestsList: (state) => state.tests.list || [],
  getAuth: (state) => state.authCustom.auth,
  id: (state) => (state.authCustom.user ? state.authCustom.user.id : undefined),
  isAdmin: (state) =>
    state.authCustom.user
      ? state.authCustom.user.role.name === "администратор"
      : false,
  isInterpretator: (state) =>
    state.authCustom.user
      ? state.authCustom.user.role.name === "интерпретатор"
      : false,
  role: (state) =>
    state.authCustom.user ? state.authCustom.user.role : undefined,
  gender: (state) =>
    state.authCustom.user ? state.authCustom.user.gender : undefined,
  profile: (state) => state.authCustom.user,
  getTestResultsList: (state) => state.testResults.list,
  getTestResultCurrent: (state) => state.testResults.current,
  getHeaderFooterVis: (state) => {
    return {
      visibleHeader: state.ui.visibleHeader,
      visibleFooter: state.ui.visibleFooter,
    };
  },
};
export default getters;
