<template>
  <section
    class="app-main"
    :style="{
      'background-image': $store.state.ui.uiBackground,
      'background-repeat': 'no-repeat',
      'background-attachment': 'inherit',
      'background-size': 'cover',
      'background-position-x': 'center',
    }"
  >
    <transition name="fade-transform" mode="out-in">
      <!-- <keep-alive :include="cachedViews"> -->
      <router-view :key="key" />
      <!-- </keep-alive> -->
    </transition>
  </section>
</template>

<script>
// import { mapGetters } from "vuex";
export default {
  name: "AppMain",
  // data() {
  //   return {
  //     uiBackgroundVal: "url('/KeyShot.jpg')"
  //   };
  // },
  // mounted() {
  //   this.$store.watch(
  //     () => this.$store.state.ui.uiBackground,
  //     uiBackground => {
  //       //console.log("uiBackground", uiBackground);
  //     }
  //   );
  //   // this.$store.watch('ui/uiBackground', value => //console.log(value), { immediate: true })
  // },
  computed: {
    key() {
      return this.$route.path;
    },
    // ...mapGetters([
    //   "uiBackground"
    //   // ...
    // ])
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.fixed-header + .app-main {
  padding-top: 50px;
  height: 100%;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: 100vh;
  }

  .fixed-header + .app-main {
    padding-top: 84px;

    height: 100%;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
