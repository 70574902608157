<template>
  <a-layout-footer
    :style="{
      padding: '20px',
    }"
  >
    <div class="footer">
      <div class="left-footer">
        <span class="copyright">
          <!-- https://www.facebook.com/memorymaltsev/ -->
          <router-link to="/">{{
            $t("general.copyrightDatedNotice")
          }}</router-link>
        </span>
        <span class="copyright">
          <a href="mailto:hello@psyhotest.com" target="_blank"
            >hello@psyhotest.com</a
          >
        </span>
      </div>
      <div class="right-footer">
        <router-link :to="`/${this.$i18n.locale}/privacy-policy`">{{
          $t("general.legal")
        }}</router-link>

        <router-link :to="`/${this.$i18n.locale}/data-deletion`"
          >{{ $t("general.dataDeletion") }}
        </router-link>
        <!-- <a href="mailto:hello@psyhotest.com" target="_blank">hello@psyhotest.com</a> -->
      </div>
    </div>
    <div ref="script" />
  </a-layout-footer>
</template>
<script>
export default {
  data() {
    return {
      // s: null,
      // x: null
    };
  },
};
</script>

<style scoped>
.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* text-align: center; */
}

.copyright {
  /* float: left; */
  /* margin-right: auto; */
  text-align: center;
}

.left-footer {
  /* margin-right: auto; */
  /* float: left; */
  text-align: left;
  display: flex;
  flex-flow: column;
  justify-content: left;
}
.right-footer {
  /* margin-right: 90px; */
  /* float: right; */

  text-align: left;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.contacts {
  padding: 10px;
  /* float: right; */
}
.privacy-policy {
  /* margin-left: 20px;
  margin-right:100px;
  text-align: center; */
  /* margin-left: 20px; */
  /* margin-right: 80px; */
  text-align: left;
  /* float: right; */
  /* float: right; */
}
</style>
