<template>
  <div class="container">
    <!-- <h1 v-if="error.statusCode === 404"> -->
    {{ $t("page-errors.404") }}
    <!-- </h1> -->
    <!-- <h1 v-else>
      {{ $t("page-errors.default") }}
    </h1> -->
    <router-link to="/">
      {{ $t("page-errors.HomePage") }}
    </router-link>
  </div>
</template>

<script>
export default {
  // layout: ({ isMobile }) => isMobile ? 'mobile' : 'default',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped>
.container {
  /* font-family: sans-serif; */
  padding-top: 10%;
  text-align: center;
}
h1 {
  font-size: 20px;
}
</style>
