import Strapi from "strapi-sdk-javascript";

// This exports the plugin object.
export default {
    // The install method will be called with the Vue constructor as
    // the first argument, along with possible options
    install(Vue, options) {
        const baseUrl =
            // porocess.env.BASE_URL ||
            process.env.NODE_ENV === "production"
                ? "https://psyhotest.com"
                : process.env.NODE_ENV === "staging"
                    ? "https://psyhotest.hierarchicalsystems.com"
                    : "http://localhost:3000";
        const apiUrl =
            process.env.API_URL ||
            (process.env.NODE_ENV === "production"
                ? "https://api.psyhotest.com/api"
                : process.env.NODE_ENV === "staging"
                    ? "https://psyhotestapi.hierarchicalsystems.com/api"
                    : "http://localhost:1337/api");
        const storageUrl =
            process.env.STORAGE_URL ||
            (process.env.NODE_ENV === "production"
                ? "https://api.psyhotest.com"
                : process.env.NODE_ENV === "staging"
                    ? "https://psyhotestapi.hierarchicalsystems.com"
                    : "http://localhost:1337");

        let strapi = new Strapi(apiUrl);
        strapi.baseUrl = baseUrl;
        strapi.apiUrl = apiUrl;
        strapi.storageUrl = storageUrl;

        Vue.prototype.$strapi = strapi;
    },
};
