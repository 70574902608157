/* eslint-disable no-console */
!(function (e, t) {
  if ("object" == typeof exports && "object" == typeof module)
    module.exports = t();
  else if ("function" == typeof define && define.amd) define([], t);
  else {
    var n = t();
    for (var o in n) ("object" == typeof exports ? exports : e)[o] = n[o];
  }
})("undefined" != typeof self ? self : this, function () {
  return (function (e) {
    function t(o) {
      if (n[o]) return n[o].exports;
      var a = (n[o] = { i: o, l: !1, exports: {} });
      return e[o].call(a.exports, a, a.exports, t), (a.l = !0), a.exports;
    }
    var n = {};
    return (
      (t.m = e),
      (t.c = n),
      (t.d = function (e, n, o) {
        t.o(e, n) ||
          Object.defineProperty(e, n, {
            configurable: !1,
            enumerable: !0,
            get: o,
          });
      }),
      (t.n = function (e) {
        var n =
          e && e.__esModule
            ? function () {
                return e.default;
              }
            : function () {
                return e;
              };
        return t.d(n, "a", n), n;
      }),
      (t.o = function (e, t) {
        return Object.prototype.hasOwnProperty.call(e, t);
      }),
      (t.p = ""),
      t((t.s = 0))
    );
  })([
    function (e, t, n) {
      "use strict";
      function o(e) {
        var t =
          arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
        (0, a.updateConfig)(t),
          (0, a.checkConfig)(),
          (0, a.loadScript)(function () {
            var t = (0, a.createMetrika)(e);
            (0, a.startTracking)(t);
          }, t.scriptSrc);
      }
      Object.defineProperty(t, "__esModule", { value: !0 }), (t.default = o);
      var a = n(1);
    },
    function (e, t, n) {
      "use strict";
      function o(e) {
        Object.keys(e).forEach(function (t) {
          l.default[t] = e[t];
        });
      }
      function a() {
        if ("undefined" != typeof document) {
          if (!l.default.id)
            throw new Error(
              "[vue-yandex-metrika] Please enter a Yandex Metrika tracking ID"
            );
          return l.default.router || "production" === l.default.env
            ? void 0
            : console.warn(
                "[vue-yandex-metrika] Router is not passed, autotracking is disabled"
              );
        }
      }
      function r(e) {
        var t =
            arguments.length > 1 && void 0 !== arguments[1]
              ? arguments[1]
              : l.default.scriptSrc,
          n = document.head || document.getElementsByTagName("head")[0],
          o = document.createElement("script");
        (o.async = !0),
          (o.charset = "utf-8"),
          (o.src = t),
          n.appendChild(o),
          (o.onload = e);
      }
      function u(e) {
        if ("production" === l.default.env) {
          var t = c({ id: l.default.id }, l.default.options),
            n = new Ya.Metrika2(t);
          return (
            (window["yaCounter" + l.default.id] = n),
            (e.prototype.$metrika = e.$metrika = n)
          );
        }
        return (
          console.warn(
            '[vue-yandex-metrika] Tracking is disabled, because env option is not "production"'
          ),
          l.default.debug &&
            console.warn(
              "[vue-yandex-metrika] DEBUG is true: you'll see all API calls in the console"
            ),
          (e.prototype.$metrika = e.$metrika = {
            addFileExtension: function () {
              l.default.debug &&
                console.log(
                  "[vue-yandex-metrika] addFileExtension:",
                  arguments
                );
            },
            extLink: function () {
              l.default.debug &&
                console.log("[vue-yandex-metrika] extLink:", arguments);
            },
            file: function () {
              l.default.debug &&
                console.log("[vue-yandex-metrika] file:", arguments);
            },
            getClientID: function () {
              l.default.debug &&
                console.log("[vue-yandex-metrika] getClientID:", arguments);
            },
            hit: function () {
              l.default.debug &&
                console.log("[vue-yandex-metrika] hit:", arguments);
            },
            notBounce: function () {
              l.default.debug &&
                console.log("[vue-yandex-metrika] notBounce:", arguments);
            },
            params: function () {
              l.default.debug &&
                console.log("[vue-yandex-metrika] params:", arguments);
            },
            reachGoal: function () {
              l.default.debug &&
                console.log("[vue-yandex-metrika] reachGoal:", arguments);
            },
            replacePhones: function () {
              l.default.debug &&
                console.log("[vue-yandex-metrika] replacePhones:", arguments);
            },
            setUserID: function () {
              l.default.debug &&
                console.log("[vue-yandex-metrika] setUserID:", arguments);
            },
            userParams: function () {
              l.default.debug &&
                console.log("[vue-yandex-metrika] userParams:", arguments);
            },
          })
        );
      }
      function i(e) {
        l.default.router &&
          l.default.router.afterEach(function (t, n) {
            l.default.ignoreRoutes.indexOf(t.name) > -1 ||
              (l.default.skipSamePath && t.path == n.path) ||
              e.hit(t.path, { referer: n.path });
          });
      }
      Object.defineProperty(t, "__esModule", { value: !0 });
      var c =
        Object.assign ||
        function (e) {
          for (var t = 1; t < arguments.length; t++) {
            var n = arguments[t];
            for (var o in n)
              Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
          }
          return e;
        };
      (t.updateConfig = o),
        (t.checkConfig = a),
        (t.loadScript = r),
        (t.createMetrika = u),
        (t.startTracking = i);
      var d = n(2),
        l = (function (e) {
          return e && e.__esModule ? e : { default: e };
        })(d);
    },
    function (e, t, n) {
      "use strict";
      Object.defineProperty(t, "__esModule", { value: !0 });
      var o = {
        id: null,
        options: {
          accurateTrackBounce: !0,
          clickmap: !0,
          defer: !1,
          ecommerce: !1,
          params: [],
          userParams: {},
          trackHash: !1,
          trackLinks: !0,
          type: 0,
          webvisor: !1,
          triggerEvent: !1,
        },
        router: null,
        env: "development",
        scriptSrc: "https://cdn.jsdelivr.net/npm/yandex-metrica-watch/tag.js",
        debug: !1,
        ignoreRoutes: [],
        skipSamePath: !0,
      };
      t.default = o;
    },
  ]);
});
