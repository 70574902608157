function replainWidgetVisibility(show) {
  // if (!process.server) {
  const x = document.getElementById("__replain_widget_iframe");
  if (x) {
    x.style.visibility = show ? "visible" : "hidden";
  }
  // }
}

export default function ({ next, store }) {
  store.commit("ui/setUiBackground", "unset"); // 'url(\'/PS_fix3.png\')');

  if (!store.state.ui.visibleHeader || !store.state.ui.visibleFooter) {
    store.commit("ui/setHeaderFooterVis", {
      visibleHeader: true,
      visibleFooter: true,
    });
  }

  // if (store.state.ui.visibleHeader && store.state.ui.visibleFooter) {
  //   replainWidgetVisibility(true);
  // } else {
  replainWidgetVisibility(false);
  // }

  if (store.state.ui.actionButtonVisible) {
    store.commit("ui/setActionButton", {
      data: { visible: false },
      callback: undefined,
    });
  }
  return next();
}
