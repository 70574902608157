<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  async mounted() {
    // console.log(this);
    // console.log(this.$strapi);
    var language = window.navigator.userLanguage || window.navigator.language;
    // console.log(language);
    this.$store.dispatch("ui/initLocale", language);
    await this.$store.dispatch("authCustom/initJWT");

    const confirmEmail = await this.$store.dispatch(
      "authCustom/getConfirmEmail"
    );
    if (confirmEmail) {
      // console.log(confirmEmail);
      this.$notification["success"]({
        message: this.$t("ui.SuccessOccurred"),
        description: this.$t("ui.SuccessOccurredConfirmEmail"),
        duration: 0,
      });
    }
    // await this.$store.dispatch("authCustom/authAction");
    // await this.$store.dispatch("tests/fetchTestsList");
  },
  track() {
    page(this.$route.fullPath);
  },
  metaInfo() {
    const fullPath = this.$route.fullPath;
    let metaTitle = "";
    let metaDescription = "";
    const locale = this.$i18n.locale;

    try {
      let key = fullPath
        ? this.trimChar(fullPath, "/").replace("/", "-")
        : "default";
      if (key === "") {
        key = "home-page";
      }
      // console.log(this.$route, key);

      const metaTitleKey = "meta.title." + key;
      const metaDescriptionKey = "meta.description." + key;
      const defaultDescription = this.$t("meta.description.default");
      const defaultTitle = this.$t("meta.title.default");
      const $te = this.$te.bind(this);
      metaTitle = $te(metaTitleKey) ? this.$t(metaTitleKey) : defaultTitle;
      metaTitle =
        metaTitle === defaultTitle
          ? metaTitle
          : metaTitle + " - " + defaultTitle;
      metaDescription = $te(metaDescriptionKey)
        ? this.$t(metaDescriptionKey)
        : defaultDescription;
      // console.log(metaDescriptionKey, metaDescription);
    } catch (err) {
      // console.log(err);
    }
    return {
      title: metaTitle,
      titleTemplate: "%s",
      htmlAttrs: {
        lang: locale, // 'en',
        amp: true,
      },
      meta: [
        { vmid: "description", name: "description", content: metaDescription },
        {
          "data-hid": "og:url",
          hid: "og:url",
          name: "og:url",
          content: fullPath,
        },
        {
          "data-hid": "author",
          hid: "author",
          name: "author",
          content: this.$t("general.SRIMemory"),
        },
        {
          "data-hid": "og:title",
          hid: "og:title",
          name: "og:title",
          content: metaTitle,
        },
        {
          "data-hid": "og:description",
          hid: "og:description",
          name: "og:description",
          content: metaDescription,
        },
        {
          "data-hid": "twitter:description",
          hid: "twitter:description",
          name: "twitter:description",
          content: metaDescription,
        },
        {
          "data-hid": "description",
          hid: "description",
          name: "description",
          content: metaDescription,
        },
        {
          "data-hid": "og:image",
          hid: "og:image",
          name: "og:image",
          content: "https://psyhotest.com/logo.png",
        },
      ],
      link: [
        {
          hid: "apple-touch-icon",
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "https://psyhotest.com/logo.png",
        },
      ],
    };
  },
  methods: {
    trimChar(string, charToRemove) {
      while (string.charAt(0) == charToRemove) {
        string = string.substring(1);
      }

      while (string.charAt(string.length - 1) == charToRemove) {
        string = string.substring(0, string.length - 1);
      }

      return string;
    },
  },
};
</script>
